import { useState, useMemo, useCallback } from 'react';
import { FEE_STRUCTURES, EBAY_SELLER_LEVELS, EBAY_STORE_TYPES, OVERSEAS_SALES } from '../utils/calculator.ts';

function useEbayFeeCalculator(initialCountry) {
    const initialCategory = Object.keys(FEE_STRUCTURES[initialCountry])[0];

    const initialFinalValueFee = FEE_STRUCTURES[initialCountry][initialCategory].finalValueFee(0);
    const initialInsertionFee = FEE_STRUCTURES[initialCountry][initialCategory].insertionFee;
    const initialTotalEbayFee = initialFinalValueFee + initialInsertionFee;

    const [state, setState] = useState({
        cost: 0.0,
        salePrice: 0.0,
        salesTax: 0.0,
        country: initialCountry,
        category: initialCategory,
        finalValueFee: initialFinalValueFee,
        insertionFee: initialInsertionFee,
        totalEbayFee: initialTotalEbayFee,
        shippingCharges: 0.0,
        shippingCost: 0.0,
        quantity: 1,
        sellerLevel: EBAY_SELLER_LEVELS[0],
        storeType: EBAY_STORE_TYPES[initialCountry][0],
        overseasSales: OVERSEAS_SALES[initialCountry][0],
        totalFee: null
    });

    const recalculateFees = useCallback((newState) => {
        const insertionFee = FEE_STRUCTURES[newState.country][newState.category].insertionFee * newState.quantity;
        const finalValueFee = FEE_STRUCTURES[newState.country][newState.category].finalValueFee(parseFloat(newState.salePrice)) * newState.quantity;
        const totalEbayFee = finalValueFee + insertionFee;
        console.log('state.country', newState.country);
        console.log('state.category', newState.category);
        console.log('state.salePrice', newState.salePrice);
        console.log('state.quantity', newState.quantity);
        setState(prevState => ({ ...prevState, insertionFee, finalValueFee, totalEbayFee }));
    }, []);

    const handleQuantityChange = useCallback(({quantity}) => {
        setState(prevState => {
            const newState = { ...prevState, quantity };
            recalculateFees(newState);
            return newState;
        });
    }, [recalculateFees]);

    const handleSalePriceChange = useCallback(({salePrice}) => {
        setState(prevState => {
            const newState = { ...prevState, salePrice };
            recalculateFees(newState);
            return newState;
        });
    }, [recalculateFees]);

    const handleCostChange = useCallback(({cost}) => {
        setState(prevState => {
            const newState = { ...prevState, cost };
            recalculateFees(newState);
            return newState;
        });
    }, [recalculateFees]);

    const handleShippingChargesChange = useCallback(({shippingCharges}) => {
        setState(prevState => {
            const newState = { ...prevState, shippingCharges };
            recalculateFees(newState);
            return newState;
        });
    }, [recalculateFees]);

    const handleShippingCostChange = useCallback(({shippingCost}) => {
        setState(prevState => {
            const newState = { ...prevState, shippingCost };
            recalculateFees(newState);
            return newState;
        });
    }, [recalculateFees]);

    const handleCountryChange = useCallback(({country}) => {
        setState(prevState => {
            const newState = { 
                ...prevState, 
                country,
                category: Object.keys(FEE_STRUCTURES[country])[0],
                storeType: EBAY_STORE_TYPES[country][0],
                overseasSales: OVERSEAS_SALES[country][0]
            }
            recalculateFees(newState);
            return newState;
        });
    }, [recalculateFees]);

    const handleCategoryChange = useCallback(({category}) => {
        setState(prevState => { 
            const newState = { ...prevState, category };
            // console.log('state.category', newState.category);
            // console.log('category', category);
            recalculateFees(newState);
            return newState;
        });
    }, [recalculateFees]);

    const handleOverseasSalesChange = useCallback(({overseasSales}) => {
        setState(prevState => ({ ...prevState, overseasSales }));
        // recalculateFees();
    }, []);

    const handleSellerLevelChange = useCallback(({sellerLevel}) => {
        setState(prevState => ({ ...prevState, sellerLevel }));
        // recalculateFees();
    }, []); 

    const handleStoreTypeChange = useCallback(({storeType}) => {
        setState(prevState => ({ ...prevState, storeType }));
        // recalculateFees();
    }, []);

    const handleSalesTaxChange = useCallback(({salesTax}) => {
        setState(prevState => ({ ...prevState, salesTax }));
    }, []);
          
    const salesTaxAmount = useMemo(() => {
        const price = (parseFloat(state.salePrice) || 0) * state.quantity;
        const taxRate = parseFloat(state.salesTax) || 0;
        return price * (taxRate / 100);
    }, [state.salePrice, state.salesTax, state.quantity]);

    const grossProfit = useMemo(() => {
        const price = parseFloat(state.salePrice) || 0 * state.quantity;
        const cost = parseFloat(state.cost) || 0 * state.quantity;
        const shippingCharges = parseFloat(state.shippingCharges) || 0 * state.quantity;
        const shippingCost = parseFloat(state.shippingCost) || 0 * state.quantity;
        const totalEbayFee = parseFloat(state.totalEbayFee) || 0;
        
        return price + shippingCharges - salesTaxAmount - totalEbayFee - cost - shippingCost;
    }, [state.salePrice, salesTaxAmount, state.totalEbayFee, state.cost, state.quantity, state.shippingCharges, state.shippingCost]);

    const profitMargin = useMemo(() => {
        const price = (parseFloat(state.salePrice) || 0) * state.quantity;
        const margin = price > 0 ? (grossProfit / price) * 100 : 0;
        return margin;
    }, [state.salePrice, grossProfit, state.quantity]);

    return {
        ...state,
        grossProfit,
        profitMargin,
        salesTaxAmount,
        setSalePrice: (value) => handleSalePriceChange({ salePrice: value || 0 }),
        setCost: (value) => handleCostChange({ cost: value || 0 }),
        setCountry: (country) => handleCountryChange({ country }),
        setCategory: (category) => handleCategoryChange({ category }),
        setOverseasSales: (overseasSales) => handleOverseasSalesChange({ overseasSales }),
        setSellerLevel: (sellerLevel) => handleSellerLevelChange({ sellerLevel }),
        setStoreType: (storeType) => handleStoreTypeChange({ storeType }),
        setShippingCharges: (shippingCharges) => handleShippingChargesChange({ shippingCharges }),
        setShippingCost: (shippingCost) => handleShippingCostChange({ shippingCost }),
        setQuantity: (quantity) => handleQuantityChange({ quantity }),
        setSalesTax: (salesTax) => handleSalesTaxChange({ salesTax }),
    };
}

export default useEbayFeeCalculator;
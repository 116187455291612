export const CANADIAN_BASE_INSERTION_FEE = 0.30;
export const US_BASE_INSERTION_FEE = 0.35;

export const EBAY_STORE_TYPES = {
  US: [
    "Basic",
    "Anchor",
    "Premium",
    "Starter",
    "Enterprise",
    "None"
  ],
  CA: [
    "Basic",
    "Anchor",
    "Premium",
    "None"
  ]
};
  
export const EBAY_SELLER_LEVELS = [
  "Top Rated",
  "Above Standard",
  "Below Standard",
];
  
export const OVERSEAS_SALES = {
  US: ["No", "Yes"],
  CA: ["Domestic", "USA", "Other"],
};

export const FEE_STRUCTURES = {
  US: {
    "Everything else": {
      insertionFee: US_BASE_INSERTION_FEE,
      finalValueFee: (salePrice) => {
        if (salePrice <= 7500) {
          return salePrice * 0.1325
        } else {
          return salePrice * 0.0235
        }
      },
    },
    "Art NFTS": {
      secondaryCategories: ["Art NFTs", "Movies & TV NFTs", "Music NFTs", "Trading Card NFTs, Collectible Card Games NFTs"],
      insertionFee: US_BASE_INSERTION_FEE,
      finalValueFee: (salePrice) => salePrice * 0.05,
    },
    "Books & Magazines": {
      insertionFee: US_BASE_INSERTION_FEE,
      finalValueFee: (salePrice) => {
        if (salePrice <= 7500) {
          return salePrice * 0.1495
        } else {
          return salePrice * 0.0235
        }
      },
    },
    "Business & Industrial": {
      secondaryCategories: ["Heavy Equipment", "Commercial Printing Presses", "Food Trucks", "Trailers & Carts"],
      insertionFee: US_BASE_INSERTION_FEE,
      finalValueFee: (salePrice) => {
        if (salePrice <= 15000) {
          return salePrice * 0.03
        } else {
          return salePrice * 0.005
        }
      },
    },
    
    "Clothing, Shoes & Accessories": {
      secondaryCategories: ["Women's Bags & Handbags"],
      insertionFee: US_BASE_INSERTION_FEE,
      finalValueFee: (salePrice) => {
        if (salePrice <= 2000) {
          return salePrice * 0.15
        } else {
          return salePrice * 0.09
        }
      },
    },
    "Coins & Paper Money": {
      secondaryCategories: ["Bullion"],
      insertionFee: US_BASE_INSERTION_FEE,
      finalValueFee: (salePrice) => {
        if (salePrice <= 7500) {
          return salePrice * 0.1325
        } else {
          return salePrice * 0.07
        }
      },
    },
    "Jewelry": {
      insertionFee: US_BASE_INSERTION_FEE,
      finalValueFee: (salePrice) => {
        if (salePrice <= 5000) {
          return salePrice * 0.15
        } else {
          return salePrice * 0.09
        }
      },
    },
    
    "Movies & TV": {
      insertionFee: US_BASE_INSERTION_FEE,
      finalValueFee: (salePrice) => {
        if (salePrice <= 7500) {
          return salePrice * 0.1495
        } else {
          return salePrice * 0.0235
        }
      },
    },
    "Music": {
      insertionFee: US_BASE_INSERTION_FEE,
      finalValueFee: (salePrice) => {
        if (salePrice <= 7500) {
          return salePrice * 0.1495
        } else {
          return salePrice * 0.0235
        }
      },
    },
    "Musical Instruments & Gear": {
      secondaryCategories: ["Guitars", "Basses"],
      insertionFee: US_BASE_INSERTION_FEE,
        finalValueFee: (salePrice) => {
        if (salePrice <= 7500) {
          return salePrice * 0.635
        } else {
          return salePrice * 0.0235
        }
      },
    },
    "Shoes": {
      secondaryCategories: ["Women's Athletic Shoes", "Men's Athletic Shoes"],
      insertionFee: US_BASE_INSERTION_FEE,
      finalValueFee: (salePrice) => {
        if (salePrice <= 2000) {
          return salePrice * 0.15
        } else {
          return salePrice * 0.09
        }
      },
    },
    "Watches": {
      secondaryCategories: ["Watches", "Parts & Accessories"],
      insertionFee: US_BASE_INSERTION_FEE,
      finalValueFee: (salePrice) => {
        if (salePrice <= 1000) {
          return salePrice * 0.15
        } else if (salePrice <= 7500) {
          return salePrice * 0.065
        } else {
          return salePrice * 0.03
        }
      },
    },
  },
  CA: {
    "Everything else": {
      insertionFee: CANADIAN_BASE_INSERTION_FEE,
      finalValueFee: (salePrice) => {
        if (salePrice <= 7500) {
          return salePrice * 0.1395
        } else {
          return salePrice * 0.0235
        }
      },
    },
    "Art NFTS": {
      insertionFee: CANADIAN_BASE_INSERTION_FEE,
      finalValueFee: (salePrice) => salePrice * 0.05,
    },
    "Books & Magazines": {
      insertionFee: CANADIAN_BASE_INSERTION_FEE,
      finalValueFee: (salePrice) => {
        if (salePrice <= 7500) {
          return salePrice * 0.1495
        } else {
          return salePrice * 0.0235
        }
      },
    },
    "Business & Industrial": {
      secondaryCategories: ["Heavy Equipment", "Commercial Printing Presses", "Food Trucks", "Trailers & Carts"],
      insertionFee: CANADIAN_BASE_INSERTION_FEE,
      finalValueFee: (salePrice) => {
        if (salePrice <= 7500) {
          return salePrice * 0.1395
        } else {
          return salePrice * 0.0235
        }
      },
    },
    "Clothing, Shoes & Accessories": {
      insertionFee: CANADIAN_BASE_INSERTION_FEE,
      finalValueFee: (salePrice) => {
        if (salePrice <= 7500) {
          return salePrice * 0.1395
        } else {
          return salePrice * 0.0235
        }
      },
    },
    "Coins & Paper Money": {
      secondaryCategories: ["Bullion"],
      insertionFee: CANADIAN_BASE_INSERTION_FEE,
      finalValueFee: (salePrice) => {
        if (salePrice <= 7500) {
          return salePrice * 0.1395
        } else {
          return salePrice * 0.0235
        }
      },
    },
    "Jewelry": {
      insertionFee: CANADIAN_BASE_INSERTION_FEE,
      finalValueFee: (salePrice) => {
        if (salePrice <= 7500) {
          return salePrice * 0.1395
        } else {
          return salePrice * 0.0235
        }
      },
    },
    "Movies & TV": {
      insertionFee: CANADIAN_BASE_INSERTION_FEE,
      finalValueFee: (salePrice) => {
        if (salePrice <= 7500) {
          return salePrice * 0.1395
        } else {
          return salePrice * 0.0235
        }
      },
    },
    "Music": {
      insertionFee: CANADIAN_BASE_INSERTION_FEE,
      finalValueFee: (salePrice) => {
        if (salePrice <= 7500) {
          return salePrice * 0.1395
        } else {
          return salePrice * 0.0235
        }
      },
    },
    "Musical Instruments & Gear": {
      insertionFee: CANADIAN_BASE_INSERTION_FEE,
      finalValueFee: (salePrice) => {
        if (salePrice <= 7500) {
          return salePrice * 0.1395
        } else {
          return salePrice * 0.0235
        }
      },
    },
    "Shoes": {
      secondaryCategories: ["Women's Athletic Shoes", "Men's Athletic Shoes"],
      insertionFee: CANADIAN_BASE_INSERTION_FEE,
      finalValueFee: (salePrice) => {
        if (salePrice <= 2000) {
          return salePrice * 0.15
        } else {
          return salePrice * 0.09
        }
      },
    },
    "Watches": {
      secondaryCategories: ["Watches", "Parts & Accessories"],
      insertionFee: CANADIAN_BASE_INSERTION_FEE,
      finalValueFee: (salePrice) => {
        if (salePrice <= 1000) {
          return salePrice * 0.15
        } else if (salePrice <= 7500) {
          return salePrice * 0.065
        } else {
          return salePrice * 0.03
        }
      },
    },
  },
};
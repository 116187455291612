/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Box, Typography, Input, Card, Grid, Divider, Button, ButtonGroup } from '@mui/joy';
import useFeeCalculator from '../hooks/useFeeCalculator';
import { Helmet } from 'react-helmet-async';
import FeeCalculatorDetails from '../components/FeeCalculatorDetails';
import Dashboard from './dashboard/Dashboard.tsx';


const StyledInput = ({ startDecorator, endDecorator, value, onChange, ...props }) => (
  <Input
    variant="soft"
    size="lg"
    startDecorator={startDecorator}
    endDecorator={endDecorator}
    value={value}
    onChange={onChange}
    placeholder="0.00"
    sx={{ width: '120px', '& input': { textAlign: 'right' } }}
    {...props}
  />
);

const InputRow = ({ label, value, color, children }) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typography level="body-md">{label}</Typography>
      {color && <Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: color }} />}
    </Box>
    {children}
  </Box>
);

const FeeCalculator = ({ platform, canonicalUrl }) => {
  const {
    cost,
    setCost,
    salePrice,
    setSalePrice,
    salesTax,
    setSalesTax,
    salesTaxAmount,
    sellerFee,
    grossProfit,
    profitMargin,
    country,
    setCountry,
  } = useFeeCalculator('US');

  const colors = {
    netProfit: '#2196f3',    // blue
    cost: '#f44336',         // red
    salesTax: '#ffc107',     // amber
    fees: '#9c27b0',         // purple
  };

  const renderCurrencyToggle = () => (
    <ButtonGroup variant="soft" sx={{ alignSelf: 'flex-start' }}>
      {[
        { code: 'US', symbol: '🇺🇸', label: 'USD' },
        { code: 'CA', symbol: '🇨🇦', label: 'CAD' }
      ].map(({ code, symbol, label }) => (
        <Button
          key={code}
          onClick={() => setCountry(code)}
          variant={country === code ? 'soft' : 'soft'}
          color={country === code ? 'primary' : 'neutral'}
          sx={{
            display: 'flex',
            gap: 1,
            minWidth: '90px',
            bgcolor: country === code ? 'var(--joy-palette-primary-200)' : undefined,
          }}
        >
          <Typography fontSize="1.2rem">{symbol}</Typography>
          <Typography level="body-md">{label}</Typography>
        </Button>
      ))}
    </ButtonGroup>
  );

  const renderPriceInputs = () => (
    <>
      <InputRow label="Sale Price">
        <StyledInput
          data-testid="sale-price-input"
          startDecorator="$"
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*\.?\d*$/.test(value) || value === '') {
              setSalePrice(value);
            }
          }}
        />
      </InputRow>

      <InputRow label="Cost of Goods" color={colors.cost}>
        <StyledInput
          data-testid="cost-input"
          startDecorator="$"
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*\.?\d*$/.test(value) || value === '') {
              setCost(value);
            }
          }}
        />
      </InputRow>
    </>
  );

  const renderTaxesAndFees = () => (
    <>
      <Typography level="body-lg" sx={{ fontWeight: 'bold' }}>Taxes and Fees</Typography>

      <InputRow label="Sales Tax Rate">
        <StyledInput
          data-testid="tax-input"
          endDecorator="%"
          value={salesTax}
          onChange={(e) => setSalesTax(parseFloat(e.target.value) || 0)}
        />
      </InputRow>

      <InputRow label="Sales Tax Amount" color={colors.salesTax}>
        <Typography level="body-md">
          ${Math.abs(salesTaxAmount).toFixed(2)}
        </Typography>
      </InputRow>

      <InputRow label="Poshmark Fee" color={colors.fees}>
        <Typography level="body-md">
          ${Math.abs(sellerFee).toFixed(2)}
        </Typography>
      </InputRow>
    </>
  );

  return (
    <Dashboard>
      <Helmet >
        <title>Poshmark Fee Calculator - Calculate your net profits after all your fees and expenses</title>
        <meta name="description" content={`Determine your ${platform} fees and potential profits after all your expenses using our free  ${platform} calculator.`} />
        <meta property="og:title" content={`${platform} Fee calculator`} />
        <meta property="og:description" content="Calculate Your Profits Instantly" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Box>
        <Typography level="h2" sx={{
          fontSize: "1.8em",
          fontWeight: 'bold',
          fontFamily: "Libre Baskerville",
          pb: 1
        }}>
          {`${platform} Calculator`}
        </Typography>
        <Typography level="body-md" sx={{ mb: 4, color: 'text.secondary' }}>
          Calculate your net profits after all your fees and expenses.
        </Typography>

        <Grid container justifyContent="left" marginTop={4}>
          <Card sx={{ 
            pt: 4,
            width: {
              xs: '100%',
              sm: '650px',
            },
            minWidth: {
              xs: '300px',
              sm: '400px',
            },
            borderRadius: 'xl'
          }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              px: 1
            }}>
              {renderCurrencyToggle()}

              {renderPriceInputs()}

              <Divider />

              {renderTaxesAndFees()}

              <Typography level="body-xs" color="text.secondary">
                {country === 'US'
                  ? "* Poshmark Fee: 20% if $15+, $2.95 if under $15"
                  : "* Poshmark Fee: 20% if C$20+, C$3.95 if under C$20"}
              </Typography>
            </Box>

            <Card
              variant="outlined"
              sx={{
                p: 3,
                mt: 3,
                bgcolor: 'white',
                boxShadow: 'lg',
                borderRadius: 'xl'
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography level="body-lg" fontWeight="lg">Profit Margin</Typography>
                </Box>
                <Typography level="body-md" fontWeight="lg" sx={{ color: profitMargin < 0 ? 'danger.500' : 'success.500' }}>
                  {profitMargin.toFixed(1)}%
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography level="body-lg" fontWeight="lg">Net Profit</Typography>
                  <Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: colors.netProfit }} />
                </Box>
                <Typography level="h2" sx={{
                  color: salePrice === 0 && cost === 0
                    ? 'neutral.500'
                    : grossProfit < 0
                      ? 'danger.500'
                      : 'success.500',
                }}>
                  {salePrice === 0 && cost === 0
                    ? '$0.00'
                    : grossProfit < 0
                      ? `-$${Math.abs(grossProfit).toFixed(2)}`
                      : `$${grossProfit.toFixed(2)}`}
                </Typography>
              </Box>

              <Box sx={{ mt: 2 }}>
                <Box sx={{
                  height: 8,
                  borderRadius: 4,
                  display: 'flex',
                  overflow: 'hidden'
                }}>
                  <Box sx={{
                    width: `${(cost / salePrice) * 100}%`,
                    bgcolor: colors.cost
                  }} />
                  <Box sx={{
                    width: `${(salesTaxAmount / salePrice) * 100}%`,
                    bgcolor: colors.salesTax
                  }} />
                  <Box sx={{
                    width: `${(sellerFee / salePrice) * 100}%`,
                    bgcolor: colors.fees
                  }} />
                  <Box sx={{
                    width: `${(grossProfit / salePrice) * 100}%`,
                    bgcolor: colors.netProfit
                  }} />
                </Box>
              </Box>
            </Card>
          </Card>
        </Grid>

        <FeeCalculatorDetails />
      </Box>
    </Dashboard>
  );
};

export default FeeCalculator;
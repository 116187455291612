import React from 'react';
import { Box, Container, Typography } from '@mui/joy';
import Dashboard from '../dashboard/Dashboard.tsx';
import { Helmet } from 'react-helmet-async';

export const Terms = () => {
  return (
    <Dashboard>
      <Helmet>
        <title>Terms and Conditions</title>
        <meta name="description" content="Terms and Conditions" />
        <meta property="og:title" content="Terms and Conditions" />
        <meta property="og:description" content="Terms and Conditions" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          py: 8,
          gap: 4,
        }}
      >
        <Typography level="h1">Terms and Conditions</Typography>
        <Typography>Last updated: March 27, 2025</Typography>

        <Box sx={{ width: '100%', maxWidth: 800 }}>
          <Typography sx={{ mb: 4 }}>
            By accessing or using our website (https://poshwatch.io), you agree to be bound by the following Terms and Conditions. If you do not agree with any part of these terms, please do not use our website.
          </Typography>

          <Typography level="h2" sx={{ mb: 2 }}>1. Use of Our Website</Typography>
          <Typography sx={{ mb: 4 }}>
            You agree to use Poshwatch only for lawful purposes and in a way that does not infringe the rights of others or restrict their use and enjoyment of the site.
          </Typography>

          <Typography level="h2" sx={{ mb: 2 }}>2. Intellectual Property</Typography>
          <Typography sx={{ mb: 4 }}>
            All content on Poshwatch (including text, graphics, logos, and software) is the property of Poshwatch or its content suppliers and is protected by copyright and other laws. You may not copy, reproduce, or distribute any content without permission.
          </Typography>

          <Typography level="h2" sx={{ mb: 2 }}>3. Accuracy of Information</Typography>
          <Typography sx={{ mb: 4 }}>
            We do our best to keep the information on Poshwatch accurate and up-to-date, but we cannot guarantee it will always be error-free or complete. Use the information at your own risk.
          </Typography>

          <Typography level="h2" sx={{ mb: 2 }}>4. Limitation of Liability</Typography>
          <Typography sx={{ mb: 4 }}>
            Poshwatch is provided "as is" and we make no warranties, express or implied. We are not liable for any damages or losses related to your use of the site.
          </Typography>

          <Typography level="h2" sx={{ mb: 2 }}>5. Changes to These Terms</Typography>
          <Typography sx={{ mb: 4 }}>
            We may update these Terms and Conditions at any time. Changes will be posted on this page with an updated date. Continued use of the website means you accept the revised terms.
          </Typography>

          <Typography level="h2" sx={{ mb: 2 }}>6. Contact Us</Typography>
          <Typography sx={{ mb: 1 }}>
            If you have any questions about these Terms and Conditions, please contact us at info@poshwatch.io
          </Typography>
        </Box>
      </Container>
    </Dashboard>
  );
};

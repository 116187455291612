/* tslint:disable */
/* eslint-disable */
/**
 * Cloud Share API
 * API for managing cloud sharing tasks and settings
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setBearerAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, BaseAPI, operationServerMap } from './base';
/**
 *
 * @export
 * @enum {string}
 */
export const ActionType = {
    Share: 'share',
    OfferToLikers: 'offer_to_likers',
    Like: 'like',
    Follow: 'follow',
    Unfollow: 'unfollow',
    ShareParty: 'share_party'
};
/**
 *
 * @export
 * @enum {string}
 */
export const LogType = {
    System: 'system',
    TaskComplete: 'task_complete',
    Error: 'error',
    Action: 'action'
};
export const OfferShippingDiscountEnum = {
    None: 'none',
    _499: '4.99',
    _595: '5.95',
    Free: 'free'
};
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Check if the authenticated user has admin privileges
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAdminStatus: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/admin/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete user logs
         * @param {string} userId
         * @param {string} closetId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLogs: (userId, closetId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteLogs', 'userId', userId);
            // verify required parameter 'closetId' is not null or undefined
            assertParamExists('deleteLogs', 'closetId', closetId);
            const localVarPath = `/api/user/{userId}/logs/{closetId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"closetId"}}`, encodeURIComponent(String(closetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Execute a task command
         * @param {string} userId
         * @param {string} command
         * @param {TaskCommand} taskCommand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeTask: (userId, command, taskCommand, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('executeTask', 'userId', userId);
            // verify required parameter 'command' is not null or undefined
            assertParamExists('executeTask', 'command', command);
            // verify required parameter 'taskCommand' is not null or undefined
            assertParamExists('executeTask', 'taskCommand', taskCommand);
            const localVarPath = `/api/task/{userId}/{command}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"command"}}`, encodeURIComponent(String(command)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(taskCommand, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get metrics for all closets with active schedules
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClosetMetrics: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/admin/get-closet-metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get user offers
         * @param {string} userId
         * @param {string} closetId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffers: (userId, closetId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getOffers', 'userId', userId);
            // verify required parameter 'closetId' is not null or undefined
            assertParamExists('getOffers', 'closetId', closetId);
            const localVarPath = `/api/user/{userId}/offers/{closetId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"closetId"}}`, encodeURIComponent(String(closetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get rolling counts for a specific action type
         * @param {string} userId
         * @param {string} closetId
         * @param {ActionType} type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRollingCounts: (userId, closetId, type, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getRollingCounts', 'userId', userId);
            // verify required parameter 'closetId' is not null or undefined
            assertParamExists('getRollingCounts', 'closetId', closetId);
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getRollingCounts', 'type', type);
            const localVarPath = `/api/user/{userId}/rolling-counts/{closetId}/{type}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"closetId"}}`, encodeURIComponent(String(closetId)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get user schedules
         * @param {string} userId
         * @param {string} closetId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedules: (userId, closetId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getSchedules', 'userId', userId);
            // verify required parameter 'closetId' is not null or undefined
            assertParamExists('getSchedules', 'closetId', closetId);
            const localVarPath = `/api/user/{userId}/schedules/{closetId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"closetId"}}`, encodeURIComponent(String(closetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get closet names for enabled schedules grouped by hour
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedulesByHour: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/admin/schedules-by-hour`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get user settings
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings: (userId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getSettings', 'userId', userId);
            const localVarPath = `/api/user/{userId}/settings`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get the count of unique users who have enabled offers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersWithOffersCount: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/api/admin/users-with-offers/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Update user offers
         * @param {string} userId
         * @param {string} closetId
         * @param {Array<Offer>} offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOffers: (userId, closetId, offer, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateOffers', 'userId', userId);
            // verify required parameter 'closetId' is not null or undefined
            assertParamExists('updateOffers', 'closetId', closetId);
            // verify required parameter 'offer' is not null or undefined
            assertParamExists('updateOffers', 'offer', offer);
            const localVarPath = `/api/user/{userId}/offers/{closetId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"closetId"}}`, encodeURIComponent(String(closetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(offer, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Update user schedules
         * @param {string} userId
         * @param {string} closetId
         * @param {Array<Schedule>} schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSchedules: (userId, closetId, schedule, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateSchedules', 'userId', userId);
            // verify required parameter 'closetId' is not null or undefined
            assertParamExists('updateSchedules', 'closetId', closetId);
            // verify required parameter 'schedule' is not null or undefined
            assertParamExists('updateSchedules', 'schedule', schedule);
            const localVarPath = `/api/user/{userId}/schedules/{closetId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"closetId"}}`, encodeURIComponent(String(closetId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(schedule, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Update user settings
         * @param {string} userId
         * @param {Settings} settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings: (userId, settings, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateSettings', 'userId', userId);
            // verify required parameter 'settings' is not null or undefined
            assertParamExists('updateSettings', 'settings', settings);
            const localVarPath = `/api/user/{userId}/settings`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(settings, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Check if the authenticated user has admin privileges
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAdminStatus(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.checkAdminStatus(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.checkAdminStatus']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Delete user logs
         * @param {string} userId
         * @param {string} closetId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLogs(userId, closetId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteLogs(userId, closetId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.deleteLogs']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Execute a task command
         * @param {string} userId
         * @param {string} command
         * @param {TaskCommand} taskCommand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeTask(userId, command, taskCommand, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.executeTask(userId, command, taskCommand, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.executeTask']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Get metrics for all closets with active schedules
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClosetMetrics(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getClosetMetrics(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.getClosetMetrics']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Get user offers
         * @param {string} userId
         * @param {string} closetId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffers(userId, closetId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getOffers(userId, closetId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.getOffers']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Get rolling counts for a specific action type
         * @param {string} userId
         * @param {string} closetId
         * @param {ActionType} type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRollingCounts(userId, closetId, type, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getRollingCounts(userId, closetId, type, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.getRollingCounts']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Get user schedules
         * @param {string} userId
         * @param {string} closetId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedules(userId, closetId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getSchedules(userId, closetId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.getSchedules']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Get closet names for enabled schedules grouped by hour
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedulesByHour(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getSchedulesByHour(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.getSchedulesByHour']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Get user settings
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(userId, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getSettings(userId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.getSettings']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Get the count of unique users who have enabled offers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersWithOffersCount(options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getUsersWithOffersCount(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.getUsersWithOffersCount']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Update user offers
         * @param {string} userId
         * @param {string} closetId
         * @param {Array<Offer>} offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOffers(userId, closetId, offer, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateOffers(userId, closetId, offer, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.updateOffers']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Update user schedules
         * @param {string} userId
         * @param {string} closetId
         * @param {Array<Schedule>} schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSchedules(userId, closetId, schedule, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateSchedules(userId, closetId, schedule, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.updateSchedules']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         *
         * @summary Update user settings
         * @param {string} userId
         * @param {Settings} settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings(userId, settings, options) {
            var _a, _b, _c;
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateSettings(userId, settings, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['DefaultApi.updateSettings']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration, basePath, axios) {
    const localVarFp = DefaultApiFp(configuration);
    return {
        /**
         *
         * @summary Check if the authenticated user has admin privileges
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAdminStatus(options) {
            return localVarFp.checkAdminStatus(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete user logs
         * @param {string} userId
         * @param {string} closetId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLogs(userId, closetId, options) {
            return localVarFp.deleteLogs(userId, closetId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Execute a task command
         * @param {string} userId
         * @param {string} command
         * @param {TaskCommand} taskCommand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executeTask(userId, command, taskCommand, options) {
            return localVarFp.executeTask(userId, command, taskCommand, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get metrics for all closets with active schedules
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClosetMetrics(options) {
            return localVarFp.getClosetMetrics(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get user offers
         * @param {string} userId
         * @param {string} closetId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOffers(userId, closetId, options) {
            return localVarFp.getOffers(userId, closetId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get rolling counts for a specific action type
         * @param {string} userId
         * @param {string} closetId
         * @param {ActionType} type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRollingCounts(userId, closetId, type, options) {
            return localVarFp.getRollingCounts(userId, closetId, type, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get user schedules
         * @param {string} userId
         * @param {string} closetId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedules(userId, closetId, options) {
            return localVarFp.getSchedules(userId, closetId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get closet names for enabled schedules grouped by hour
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchedulesByHour(options) {
            return localVarFp.getSchedulesByHour(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get user settings
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(userId, options) {
            return localVarFp.getSettings(userId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get the count of unique users who have enabled offers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersWithOffersCount(options) {
            return localVarFp.getUsersWithOffersCount(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update user offers
         * @param {string} userId
         * @param {string} closetId
         * @param {Array<Offer>} offer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOffers(userId, closetId, offer, options) {
            return localVarFp.updateOffers(userId, closetId, offer, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update user schedules
         * @param {string} userId
         * @param {string} closetId
         * @param {Array<Schedule>} schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSchedules(userId, closetId, schedule, options) {
            return localVarFp.updateSchedules(userId, closetId, schedule, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update user settings
         * @param {string} userId
         * @param {Settings} settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings(userId, settings, options) {
            return localVarFp.updateSettings(userId, settings, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     *
     * @summary Check if the authenticated user has admin privileges
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    checkAdminStatus(options) {
        return DefaultApiFp(this.configuration).checkAdminStatus(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete user logs
     * @param {string} userId
     * @param {string} closetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    deleteLogs(userId, closetId, options) {
        return DefaultApiFp(this.configuration).deleteLogs(userId, closetId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Execute a task command
     * @param {string} userId
     * @param {string} command
     * @param {TaskCommand} taskCommand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    executeTask(userId, command, taskCommand, options) {
        return DefaultApiFp(this.configuration).executeTask(userId, command, taskCommand, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get metrics for all closets with active schedules
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getClosetMetrics(options) {
        return DefaultApiFp(this.configuration).getClosetMetrics(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get user offers
     * @param {string} userId
     * @param {string} closetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getOffers(userId, closetId, options) {
        return DefaultApiFp(this.configuration).getOffers(userId, closetId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get rolling counts for a specific action type
     * @param {string} userId
     * @param {string} closetId
     * @param {ActionType} type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getRollingCounts(userId, closetId, type, options) {
        return DefaultApiFp(this.configuration).getRollingCounts(userId, closetId, type, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get user schedules
     * @param {string} userId
     * @param {string} closetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getSchedules(userId, closetId, options) {
        return DefaultApiFp(this.configuration).getSchedules(userId, closetId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get closet names for enabled schedules grouped by hour
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getSchedulesByHour(options) {
        return DefaultApiFp(this.configuration).getSchedulesByHour(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get user settings
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getSettings(userId, options) {
        return DefaultApiFp(this.configuration).getSettings(userId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get the count of unique users who have enabled offers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getUsersWithOffersCount(options) {
        return DefaultApiFp(this.configuration).getUsersWithOffersCount(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Update user offers
     * @param {string} userId
     * @param {string} closetId
     * @param {Array<Offer>} offer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    updateOffers(userId, closetId, offer, options) {
        return DefaultApiFp(this.configuration).updateOffers(userId, closetId, offer, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Update user schedules
     * @param {string} userId
     * @param {string} closetId
     * @param {Array<Schedule>} schedule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    updateSchedules(userId, closetId, schedule, options) {
        return DefaultApiFp(this.configuration).updateSchedules(userId, closetId, schedule, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Update user settings
     * @param {string} userId
     * @param {Settings} settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    updateSettings(userId, settings, options) {
        return DefaultApiFp(this.configuration).updateSettings(userId, settings, options).then((request) => request(this.axios, this.basePath));
    }
}

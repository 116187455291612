import React from 'react';
import { Box, Container, Typography, Grid, Link, Button } from '@mui/material';
import { ThemeProvider } from "@mui/material/styles";
import { darkTheme, colors } from '../customTheme';
import { styled } from '@mui/material/styles';
import PATHS from "../utils/paths";
import { Helmet } from 'react-helmet-async';
import StyledDivider from './StyledDivider';
import InstagramIcon from '@mui/icons-material/Instagram';
import RedditIcon from '@mui/icons-material/Reddit';
import FacebookIcon from '@mui/icons-material/Facebook';
import PinterestIcon from '@mui/icons-material/Pinterest';

const StyledLink = styled(Link)`
  color: ${darkTheme.palette.text.secondary};
  text-decoration: none;
  font-size: 0.9em;
  margin-bottom: 10px;
  text-align: start;
  align-items: start;
  &:hover {
    text-decoration: underline;
  }
`;

const LinkContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  text-align: start;
  align-items: start;
`;

const SectionTitle = ({ children }) => {
  return <Typography
    variant="h4"
    fontWeight="bold"
    marginBottom="10px"
    color="#FFF" style={{
      fontSize: "0.9rem"
    }}>{children}</Typography>
}

const Footer = ({ disableBgColor, hideLinks }) => {
  return (
    <ThemeProvider theme={darkTheme}>
      {!disableBgColor && <Helmet bodyAttributes={{
        style: `background-color : ${darkTheme.palette.background.default}`
      }} />}
      <Container
        maxWidth={false}
        sx={{
          padding: {
            xs: 0,
          },
        }}
        style={{
          backgroundColor: darkTheme.palette.background.default,
          paddingTop: "0px"
        }}>
        <StyledDivider />

        <Box paddingTop={4} paddingBottom={2}>
          {!hideLinks && <Container
            sx={{
              padding: {
                xs: 0,
              },
            }}
            maxWidth="lg" align="center">
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={3}>
                <LinkContainer>
                  <SectionTitle>Charts</SectionTitle>
                  <StyledLink href={PATHS.TOP_BRANDS} variant="body2">Top Brands</StyledLink>
                  <StyledLink href={PATHS.TOP_SELLERS} variant="body2">Top Sellers</StyledLink>
                  <StyledLink href={PATHS.TOP_CATEGORIES} variant="body2">Top Categories</StyledLink>
                </LinkContainer>
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <LinkContainer>
                  <SectionTitle>Top Categories</SectionTitle>
                  {
                    PATHS.CATEGORIES.map((cat, index) => {
                      return (<StyledLink key={index} href={cat.path} variant="body2">{cat.name}</StyledLink>);
                    })
                  }
                </LinkContainer>
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <LinkContainer>
                  <SectionTitle>Free Tools</SectionTitle>
                  <StyledLink href={PATHS.PRODUCT_RESEARCH} variant="body2">Product Research</StyledLink >
                  <StyledLink href={PATHS.POSHMARK_ASSISTANT} variant="body2">Closet Sharer</StyledLink >
                  <StyledLink href={PATHS.POSHMARK_LISTING_ANALYZER} variant="body2"> Poshmark Listing Analyzer</StyledLink >
                  <StyledLink href={PATHS.EBAY_LISTING_ANALYZER} variant="body2">Ebay Listing Analyzer</StyledLink >
                  {/* <StyledLink href={PATHS.MERCARI_LISTING_ANALYZER} variant="body2">Mercari Listing Analyzer</StyledLink > */}
                  {/* <StyledLink href={PATHS.DEPOP_LISTING_ANALYZER} variant="body2">Depop Listing Analyzer</StyledLink > */}
                  <StyledLink href={PATHS.GRAILED_LISTING_ANALYZER} variant="body2">Grailed Listing Analyzer</StyledLink >
                  <StyledLink href={PATHS.FEE_CALCULATOR} variant="body2">Fee Calculator</StyledLink >
                </LinkContainer>
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <LinkContainer>
                  <SectionTitle>Poshmark Guides</SectionTitle>
                  <StyledLink href={`${PATHS.GUIDES}/how-to-start-selling-on-poshmark`} variant="body2">Getting Started</StyledLink >
                  <StyledLink href={`${PATHS.GUIDES}/how-create-poshmark-listings-that-sell`} variant="body2">How to Create Poshmark Listings that Sell</StyledLink >
                  <StyledLink href={`${PATHS.GUIDES}/poshmark-listing-template`} variant="body2">The Best Poshmark Listing Template</StyledLink >
                </LinkContainer>
              </Grid>
            </Grid>
          </Container>}
        </Box>

        <Box paddingBottom={4}>
          <Container maxWidth="lg" align="center">
            <Button component={Link} style={{ color: colors.alabaster }} href="https://www.facebook.com/groups/poshwatch">
              <FacebookIcon />
            </Button>
            <Button style={{ color: colors.alabaster }} href="https://www.reddit.com/r/poshwatch/">
              <RedditIcon />
            </Button>
            <Button style={{ color: colors.alabaster }} href="https://www.instagram.com/poshwatch.io/">
              <InstagramIcon />
            </Button>
            <Button style={{ color: colors.alabaster }} href="https://www.pinterest.ca/poshwatch_/">
              <PinterestIcon />
            </Button>
          </Container>
        </Box>

        <StyledDivider />
        <Container maxWidth="lg">
          <Typography variant="body2" align="center" sx={{ pt: 2, pb: 1 }}>
            © {new Date().getFullYear()} Poshwatch. All rights reserved.
          </Typography>
          <Typography
            variant="body2"
            align="center"
            sx={{ pb: 4 }}>
            Poshwatch is not affiliated with, endorsed by, sponsored by or otherwise related to Poshmark, Inc. or any of its affiliates or subsidiaries.
          </Typography>
        </Container>
      </Container>
    </ThemeProvider>
  );
};

export default Footer;

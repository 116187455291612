import { colors } from '../customTheme';

const howToMeasure = `
~~~~light,${colors.white}
# How To Take Measurements For Poshmark Listings

<div style="text-align: center; font-family: Inter; margin-bottom: 6px">
Last updated Jan. 13, 2025
</div>
<div style="text-align: center; font-family: Inter">
Written by Alice
</div>
\n&nbsp;\n

#### Introduction

How about we put our feet in our customers' shoes a bit?

You have an important event coming up. So, you’ve spent hours scrolling through Poshmark to find a dress for the occasion. You spot a  banger, you’ve double-checked its your size, medium. You inspected the photos, and convinced yourself that its the one.

A few days later, it arrives. 

You’re happy; But, you try the dress, and it runs small.

Now, you have to scramble for alternatives in addition to returning the dress and dealing with customer service. Sounds like stress, right? This is the nightmare everyone wants to avoid. You want to ship an item and move on to the next sale. 

When shopping online, having the right measurements is everything.

Providing accurate measurements builds trust, professionalism, and makes sure your customers feel confident in their purchase. So let’s get into the nitty-gritty of how to measure your items. 

~~~~light,${colors.white}
#### Chapter 1 - Why is measuring important?

Measurements decrease returns and keep your customers happy. Simple.

No one wants to deal with returns. Not the Buyer, Poshmark, or Resellers. It's a hassle, it's expensive and it's a waste of time. 

Here are the key benefits of providing accurate sizing & measurements:

- **Saves time:** Providing details upfront reduces repetitive questions from buyers, freeing you to focus on growing your business. Imagine not having to answer the same question about measurements ten times a week!

- **Build buyer confidence:** Detailed descriptions with accurate measurements reassure buyers, making them more likely to click “Buy Now.” Shoppers appreciate knowing exactly what they’re getting, which reduces hesitation and increases trust that they'll be happy with their purchase.

~~~~light,${colors.white}
#### Chapter 2 - Tools needed for measuring

The good news is that you don’t need to raid a hardware store to be able to properly measure your items. 

All you need are a few items:

- **[Measuring tape](https://amzn.to/3PGRuoJ):** A soft, flexible tape is a must for measuring curved or flat surfaces (actually, this is needed for almost everything). It ensures accurate dimensions.

- **Flat surface:** It’s best to use a sturdy table or desk to lay items flat for consistent and precise measurements. P.S. Do not use your lap.

- **[Mannequin or hanger](https://amzn.to/4cYEk0x):** Ideal for displaying garments while ensuring accurate length and fit measurements.

- **[Scale](https://amzn.to/3Wy3ax5):** This is optional but useful for weighing heavier items like shoes or bags, providing buyers with additional helpful details.

Include the measurements in your listing description and if you have time, take a photo of the item next to the measuring tape so the buyer can see the measurements.

~~~~light,${colors.white}
#### Chapter 3 - How to Measure: A Category Breakdown

Every item has different sections, which requires different types of techniques for measuring. And while some areas might seem insignificant, they are important to buyers.

Here’s how to measure the most important parts:

\n&nbsp;\n
##### 3.1 - Shirts and Jackets

- **Bust:** Measure from underarm to underarm seams while the garment is laid flat. Double the measurement to account for the full bust.

- **Length:** Measure from the shoulder seam to the bottom hem. Flip the garment over and measure from the back for accuracy.

- **Shoulders:** Measure the distance between the shoulder seams across the back of the garment.

- **Sleeve Length:** Start at the shoulder seam and measure down to the end of the sleeve.

\n&nbsp;\n
##### 3.2 - Pants

- **Waist:** Measure across the waistband while the pants are flat. Double this measurement for the total waist circumference.

- **Rise:** Measure from the crotch seam to the top of the waistband. This is an essential detail for buyers.

- **Inseam:** Measure along the inner seam from the crotch to the end of the pant leg.

- **Hips:** Measure the widest part of the pants, typically around 7-9 inches below the waistband

\n&nbsp;\n
##### 3.3 - Dresses

- **Waist:** Measure across the waistband and double it for the total circumference.

- **Hips:** Measure the widest part of the skirt, end to end, while laid flat.

- **Length:** Measure from the top of the waistband to the bottom hem of the skirt.

\n&nbsp;\n
##### 3.4 - Shoes & Boots

- **Calf Circumference:** Measure around the widest part of the boot shaft.

- **Boot Shaft:** Measure from the top of the heel to the top edge of the boot.

\n&nbsp;\n
##### 3.5 - Watches

- **Case Diameter:** Measure the watch case at its widest point, excluding the crown and buttons.

- **Band Length:** Measure the full length of the watch strap, not including the case.

- **Band Width:** Measure the width of the strap where it attaches to the case.

- **Case Thickness:** Measure the depth of the watch case from the top of the glass to the back.

- **Wrist Circumference:** Use a soft tape measure to measure where the watch will sit on the wrist. This ensures the strap will fit comfortably.

\n&nbsp;\n
##### 3.6 - Handbags

- **Height:** Measure from the base to the top of the bag.

- **Width:** Measure across the bottom of the bag, end to end.

- **Depth:** Measure the side panel from front to back.

- **Strap Drop:** Measure from the top of the strap to the bag’s opening to ensure proper fit when carried.

~~~~light,${colors.white}
#### Chapter 4 - Do’s and Don’ts of Measuring

Now that you know how to measure, what to measure and what to use for measuring, let’s go over some tips to keep in mind when measuring your items.

\n&nbsp;\n
##### 4.1 - Do’s

- **Double-check:** Measure twice; list once. Nobody wants to fix typos or tape-measure errors after the fact.

- **Use natural light:** Simply to see better and ensure accuracy. This will save you from squinting at faint markings.

- **Be honest:** Include every measurement, even the ones that might make buyers pause. Honesty equals trust.

- **Add context:** Mention if the fabric stretches or if the fit runs small. A little extra detail goes a long way.

\n&nbsp;\n
##### 4.2 - Don’ts

- **Guess:** If you did not measure, it's wrong. 

- **Ignore irregularities:** If a garment has unique features, measure them too. Buyers appreciate the extra effort.

- **Forget to save:** Record your measurements in a notebook or app for future reference.

- **Use old tools:** If your tape is warped or stretched out, toss it. Use the best tools to get the most accurate measurements. 

Taking measurements might not be the most glamorous part of your Poshmark business, but it’s one of the most important. It’s like setting the foundation for a house—get it wrong, and everything else crumbles.

That won’t happen though; this guide covers all you need to know.

Last thing, even if you are able to find the measurements from the retailer, it's still a good idea to measure the item yourself. As garments and other items can change in how they fit over time.

~~~~light,${colors.white}
#### Tools & Resources

After taking your measurements, your next step is to perfect your product images. Check out this blog about [how to take professional photos on Poshmark](https://poshwatch.io/guides/how-to-take-professional-poshmark-photos).

I’ve also prepared one about [how to list on Poshmark](https://poshwatch.io/guides/how-create-poshmark-listings-that-sell). 

Enjoy!`;

export default howToMeasure;
import React from "react";
import FeeCalculator from "../FeeCalculator";
import PATHS from '../../utils/paths';
const canonicalUrl = `${window.location.origin}${PATHS.FEE_CALCULATOR}`;

export const PoshmarkCalculator = () => {
  return (
    <FeeCalculator 
      platform="Poshmark"
      canonicalUrl ={canonicalUrl}
    />
  );
};
import TimeToShare from '../images/time-to-share.jpg';
import ProductResearchImage from '../images/product_research/prod-research-screen.png';
import NannyGIF from '../images/reseller_tips/nanny-yea-right.gif';
import { colors } from '../customTheme';

const resellerTips = `
~~~~light,${colors.white}
# 9 Things Every Poshmark Reseller Should Do In 2025

<div style="text-align: center; font-family: Inter; margin-bottom: 6px">
Last updated Dec. 8, 2024
</div>
<div style="text-align: center; font-family: Inter">
Written by Alice
</div>
\n&nbsp;\n

Over the past three years, we've learned alot from our research. 

Here are our top 9 tips to help you boost your sales and enjoy the journey. Etch them in to your brain and make them a habit you do without thinking.

![Best time to share on Poshmark](${TimeToShare})

~~~~light,${colors.white}
#### 1. Join Reseller Groups

One of the fastest ways to grow as a reseller is to join groups. 

The wealth of knowledge and experience in these groups is invaluable. It's the ultimate learning hack as you benefit from the knowlege and mistakes of others, which helps you to level up way more quickly than if you were to do it alone.

These groups are filled with other resellers who are willing to share their knowledge, offer mentorship and help you grow your business. Its also comforting to share the ups and downs of the journey with others who are going through the same thing.

Our favorites are:

###### Reddit

[Behind The Closet Door](https://www.reddit.com/r/BehindTheClosetDoor/)

###### Facebook

[Poshmark For Beginners](https://www.facebook.com/groups/2697939480316340)

These are free, but if you find a paid group that has good reviews, don't be afraid to invest the money even if it means trying it for a month or so first. It will save you a lot of time and help you grow your business.

~~~~light,${colors.white}
#### 2. Always Do Research

I can't stress this enough.

You would be surprised how many people don't do this. 

Everyone gets excited about finding a brand they either love, hear about in groups. They spot the item while thrifting, their heart races with excitement as they snatch it thinking they found a fast-selling gem.

Don't fall into this trap. 

You may get lucky and sell it, but you never want to depend on luck. Put on your business hat and do the research. Yes, it takes time, but it will save you a lot of money, space in your inventory and headaches in the long run.

Do you want to have a successful reselling business? or just be a hobbyist? 

If its the later, then do the research and remember this: 

No research = low sales.

Use our [Product research tool](https://www.poshwatch.io/product-research) it offers all the metrics to help with finding the best items to sell on Poshmark.

![Product Research Tool](${ProductResearchImage})

~~~~light,${colors.white}
#### 3. Don't Blame the Algorithm

![Get real](${NannyGIF})

The algorithm is not out to get you. 

It's not trying to bury your listings and make you fail. Periodic changes are normal for search engines and necessary in order to improve search results over time.

If you're not seeing the results you want, it's not because of the algorithm, honey it's you. There is a flaw in your strategy and you need to identify it and adjust.

This is tough love, but it's the truth. 

Blaming the algorithm is a cop out and it's not going to help you grow your business. Does it suck sometimes? Yes, but with some tweaks to your strategy and patience, you can make it work.

Here are 3 main things that could be causing slow sales:

- **Not Researching**: So you are not finding the items with the highest sell-through rate and best chance of selling. We spoke about this in the previous tip. Learn how to do [product research](https://www.poshwatch.io/product-research).

- **Listings not optimized:** Which means you are not ranking high in the results. Learn how to [optimize your listings](https://poshwatch.io/guides/how-create-poshmark-listings-that-sell).

- **Pricing too high:** Your pricing is just not competitive.

Experiment with different strategies to see what works best for you. Thats what all the successful resellers are doing.

~~~~light,${colors.white}
#### 4. List Daily, Sell More

Listing new items daily is the golden rule. 

It's not about playing to an algorithm; it's about constantly offering fresh picks to your buyers. If you’re short on inventory, relist older items. It keeps your closet active and improves visibility.

~~~~light,${colors.white}
#### 5. Relist Old Items

Relisting old items is a great way to keep your closet active and improve visibility. We recommend relisting items every 30 days and at latest 60 days.

Fresh items are more likely to sell than old ones as they are more likely to appear higher in search results.

~~~~light,${colors.white}
#### 6. Share Your Items, Not Others'

Focus on sharing your own closet. 

When you share your items, they climb higher in search results. Other people's shares? They don’t impact your sales. So, prioritize your closet and save time.

~~~~light,${colors.white}
#### 7. Smart Pricing: Hit the Sweet Spots

Pricing is crucial. 

If you price your items too high, they won't sell. If you price them too low, you'll lose money. You need to find the sweet spot.

Also be mindful that most items on Poshmark are sold via offers so you need to account for that so you dont fall below your target price.

Avoid odd numbers like $55 or $105. Instead, aim for $50 or $100. These are magic numbers in Poshmark’s price filters, making your items visible in more searches. If you need help with pricing, Our [Product Research Tool](https://www.poshwatch.io/product-research) can help you set the best price based on the demand and competition.

~~~~light,${colors.white}
#### 8. Fast Offers to Likers

When someone likes an item, send them an offer fast. 

Strike while the iron is hot as they're more likely to buy when the item is fresh in their mind and they are still felling excited about it. Remember emotions drive sales. Send offers quickly with automation tools. This strategy is far more effective than waiting to send bulk offers.

~~~~light,${colors.white}
#### 9. Stop the Follower Chase

A huge follower count feels great but doesn't necessarily boost sales. 

Focus on quality listings and connecting with genuine buyers. That's where the real business happens.

Running my online reselling business is an adventure. These tips have been my roadmap to success. Remember, every small step you take refines your strategy and brings you closer to your goals. Let’s keep the hustle fun and rewarding!

`;

export default resellerTips;
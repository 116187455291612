import React from 'react';
import Dashboard from '../../pages/dashboard/Dashboard.tsx';
import { Helmet } from 'react-helmet-async';
import { Container, Typography, Sheet, Stack, Skeleton, Button, Grid, Tabs, TabList, Tab, TabPanel, Table } from '@mui/joy';
import { createApiClient } from '../../api/cloud_share_client.ts';
import { ClosetMetrics } from 'cloud_share_ts_client';

// Component for displaying users with offers
const UsersWithOffersCard = ({ count, isLoading }: { count: number; isLoading: boolean }) => (
  <Sheet variant="outlined" sx={{ p: 2, borderRadius: 'sm' }}>
    <Typography level="title-lg" sx={{ mb: 2 }}>Users with Offers</Typography>
    {isLoading ? (
      <Typography><Skeleton>Loading users with offers count...</Skeleton></Typography>
    ) : (
      <Typography>{count} users have enabled offers</Typography>
    )}
  </Sheet>
);

// Component for displaying schedules by hour
const SchedulesByHourCard = ({
  schedulesByHour,
  isLoading
}: {
  schedulesByHour: Record<string, Array<{ userId: string; closetId: string }>>;
  isLoading: boolean;
}) => {
  const [showUsernames, setShowUsernames] = React.useState(false);

  // Simplified helper function to get users for an hour
  const getUsersForHour = (hour: number): Array<{ userId: string; closetId: string }> => {
    return schedulesByHour[hour.toString()] || [];
  };

  // Helper function to convert UTC to PST
  const utcToPst = (utcHour: number): number => {
    const pstHour = (utcHour - 8 + 24) % 24;
    return pstHour;
  };

  // Helper function to format hour
  const formatHour = (hour: number): string => {
    const formattedHour = (hour % 12 || 12).toString();
    const ampm = hour < 12 ? 'AM' : 'PM';
    return `${formattedHour} ${ampm}`;
  };

  return (
    <Sheet variant="outlined" sx={{ p: 2, borderRadius: 'sm' }}>
      <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
        <Typography level="title-lg">Schedules by Hour</Typography>
        <Button
          variant="outlined"
          onClick={() => setShowUsernames(!showUsernames)}
          size="sm"
        >
          Show {showUsernames ? 'Counts' : 'Usernames'}
        </Button>
      </Stack>

      {isLoading ? (
        <Grid container spacing={2} columns={4}>
          {[...Array(24)].map((_, index) => (
            <Grid xs={1} key={index}>
              <Sheet variant="soft" sx={{ p: 2, height: '100px' }}>
                <Typography><Skeleton>Loading...</Skeleton></Typography>
              </Sheet>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container spacing={2} columns={showUsernames ? 2 : 4}>
          {[...Array(24)].map((_, i) => {
            const index = (i + 8) % 24; // Start with 12 AM PST (8 UTC)
            const users = getUsersForHour(index);
            const pstHour = utcToPst(index);

            return (
              <Grid xs={1} key={index}>
                <Sheet
                  variant="soft"
                  sx={{
                    p: 2,
                    height: showUsernames ? 'auto' : '100px',
                    minHeight: '100px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                  }}
                >
                  <Typography level="title-sm" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>{formatHour(pstHour)}</span>
                    <span>{index}:00 UTC</span>
                  </Typography>
                  {showUsernames ? (
                    <>
                      <Typography level="body-sm" sx={{ color: 'neutral.500' }}>
                        {users.length} user{users.length !== 1 ? 's' : ''}
                      </Typography>
                      <Typography level="body-sm">
                        {users.length > 0 ? (
                          users.sort((a, b) => a.closetId.localeCompare(b.closetId)).map((user, i) => (
                            <React.Fragment key={user.userId}>
                              {user.closetId}
                              {i < users.length - 1 && <br />}
                            </React.Fragment>
                          ))
                        ) : (
                          'No users'
                        )}
                      </Typography>
                    </>
                  ) : (
                    <Typography level="h4">{users.length}</Typography>
                  )}
                </Sheet>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Sheet>
  );
};

// New component for displaying closet metrics
const ClosetMetricsCard = ({ metrics, isLoading }: { metrics: ClosetMetrics[]; isLoading: boolean }) => {
  // Sort metrics by shareCount24h in descending order
  const sortedMetrics = [...metrics].sort((a, b) => b.shareCount24h - a.shareCount24h);

  // Calculate summary statistics
  const totalClosets = metrics.length;
  const activeClosets = metrics.filter(m => m.isActive).length;
  const inactiveClosets = totalClosets - activeClosets;
  const subscribedClosets = metrics.filter(m => m.isSubscribed).length;
  const unsubscribedClosets = totalClosets - subscribedClosets;
  const averageShares = metrics.reduce((sum, m) => sum + m.shareCount24h, 0) / totalClosets || 0;

  return (
    <Sheet variant="outlined" sx={{ p: 2, borderRadius: 'sm' }}>
      <Typography level="title-lg" sx={{ mb: 2 }}>Closet Metrics</Typography>
      {isLoading ? (
        <Stack spacing={2}>
          {[...Array(5)].map((_, i) => (
            <Typography key={i}><Skeleton>Loading metrics...</Skeleton></Typography>
          ))}
        </Stack>
      ) : (
        <>
          <Sheet variant="soft" sx={{ p: 2, mb: 2 }}>
            <Grid container spacing={2} columns={3}>
              <Grid xs={1}>
                <Typography level="title-sm">Total Closets</Typography>
                <Typography level="h4">{totalClosets}</Typography>
              </Grid>
              <Grid xs={1}>
                <Typography level="title-sm">Active</Typography>
                <Typography level="h4">{activeClosets}</Typography>
              </Grid>
              <Grid xs={1}>
                <Typography level="title-sm">Inactive</Typography>
                <Typography level="h4">{inactiveClosets}</Typography>
              </Grid>
              <Grid xs={1}>
                <Typography level="title-sm">Subscribed</Typography>
                <Typography level="h4">{subscribedClosets}</Typography>
              </Grid>
              <Grid xs={1}>
                <Typography level="title-sm">Unsubscribed</Typography>
                <Typography level="h4">{unsubscribedClosets}</Typography>
              </Grid>
              <Grid xs={1}>
                <Typography level="title-sm">Avg 24h Shares</Typography>
                <Typography level="h4">{averageShares.toFixed(1)}</Typography>
              </Grid>
            </Grid>
          </Sheet>
          <Sheet variant="soft" sx={{ p: 2 }}>
            <Table>
              <thead>
                <tr>
                  <th style={{width: 50}}>#</th>
                  <th>Closet ID</th>
                  <th>Subscribed</th>
                  <th>24h Shares</th>
                  <th>Shares/Hour</th>
                  <th>Active</th>
                </tr>
              </thead>
              <tbody>
                {sortedMetrics.map((metric, index) => (
                  <tr 
                    key={metric.closetId}
                    style={{ backgroundColor: metric.isSubscribed ? 'var(--joy-palette-success-softBg)' : 'inherit' }}
                  >
                    <td>{index + 1}</td>
                    <td>{metric.closetId}</td>
                    <td>{metric.isSubscribed ? 'Yes' : 'No'}</td>
                    <td>{metric.shareCount24h}</td>
                    <td>{(metric.shareCount24h / 24).toFixed(1)}</td>
                    <td>{metric.isActive ? 'Yes' : 'No'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Sheet>
        </>
      )}
    </Sheet>
  );
};

// Custom hook for fetching admin data
const useAdminData = (activeTab: number) => {
  const [schedulesByHour, setSchedulesByHour] = React.useState<Record<string, Array<{ userId: string; closetId: string }>>>({});
  const [usersWithOffersCount, setUsersWithOffersCount] = React.useState<number>(0);
  const [closetMetrics, setClosetMetrics] = React.useState<ClosetMetrics[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const client = await createApiClient();

        switch (activeTab) {
          case 0: // Metrics tab
            const metricsResponse = await client.getClosetMetrics();
            setClosetMetrics(metricsResponse.data.metrics || []);
            break;
          case 1: // Schedules tab
            const schedulesResponse = await client.getSchedulesByHour();
            setSchedulesByHour(schedulesResponse.data);
            break;
          case 2: // Offers tab
            const offersResponse = await client.getUsersWithOffersCount();
            setUsersWithOffersCount(offersResponse.data.count);
            break;
        }
      } catch (err) {
        console.error('Error fetching admin data:', err);
        setError(err instanceof Error ? err : new Error('Unknown error occurred'));
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [activeTab]); // Add activeTab as dependency

  return { schedulesByHour, usersWithOffersCount, closetMetrics, isLoading, error };
};

// Main Admin component
export const Admin = () => {
  const [activeTab, setActiveTab] = React.useState(0);
  const { schedulesByHour, usersWithOffersCount, closetMetrics, isLoading, error } = useAdminData(activeTab);

  return (
    <Dashboard>
      <Helmet>
        <title>Admin Dashboard | Poshwatch</title>
        <meta name="description" content="Poshwatch Admin Dashboard " />
        <meta property="og:title" content="Admin Dashboard" />
        <meta property="og:description" content="Poshwatch Admin Dashboard" />
        <meta property="og:url" content="https://poshwatch.io/admin" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Container>
        {error ? (
          <Typography color="danger">Error loading admin data. Please try again later.</Typography>
        ) : (
          <Tabs
            value={activeTab}
            onChange={(event, value) => setActiveTab(value as number)}
            sx={{ bgcolor: 'background.surface' }}
          >
            <TabList>
              <Tab>Metrics</Tab>
              <Tab>Schedules</Tab>
              <Tab>Offers</Tab>
            </TabList>
            <TabPanel value={0}>
              <ClosetMetricsCard metrics={closetMetrics} isLoading={isLoading} />
            </TabPanel>
            <TabPanel value={1}>
              <SchedulesByHourCard schedulesByHour={schedulesByHour || {}} isLoading={isLoading} />
            </TabPanel>
            <TabPanel value={2}>
              <UsersWithOffersCard count={usersWithOffersCount} isLoading={isLoading} />
            </TabPanel>
          </Tabs>
        )}
      </Container>
    </Dashboard>
  );
};

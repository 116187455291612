import { Configuration, DefaultApi } from 'cloud_share_ts_client';
import { getAuth, onAuthStateChanged } from "firebase/auth";

export const createApiClient = async (): Promise<DefaultApi> => {
  let token: string | undefined;

  try {
    const auth = getAuth();
    await new Promise((resolve) => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        unsubscribe();
        resolve(user);
      });
    });

    const user = auth.currentUser;
    if (user) {
      token = await user.getIdToken();
    }
  } catch (error) {
    console.error("Error getting auth token:", error);
  }

  const configuration = new Configuration({
    baseOptions: {
      headers: {
        'Content-Type': 'application/json',
        ...(token && { 'Authorization': `Bearer ${token}` })
      }
    }
  });

  return new DefaultApi(configuration);
};

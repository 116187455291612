import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import PATHS from '../utils/paths';
import { Divider, useMediaQuery } from '@mui/material';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { colors } from '../customTheme.js';

const charts = [
  { title: 'Top Brands', path: PATHS.TOP_BRANDS },
  { title: 'Top Sellers', path: PATHS.TOP_SELLERS },
  { title: 'Top Categories', path: PATHS.TOP_CATEGORIES },
];

const analyzers = [
  { title: 'Poshmark Listing Analyzer', path: PATHS.POSHMARK_LISTING_ANALYZER },
  { title: 'Ebay Listing Analyzer', path: PATHS.EBAY_LISTING_ANALYZER },
  // { title: 'Mercari Listing Analyzer', path: PATHS.MERCARI_LISTING_ANALYZER },
  { title: 'Grailed Listing Analyzer', path: PATHS.GRAILED_LISTING_ANALYZER },
];

const tools = [
  { title: 'Product Research', path: PATHS.PRODUCT_RESEARCH },
  { title: 'Closet Sharer', path: PATHS.POSHMARK_ASSISTANT },
  { title: 'Fee Calculator', path: PATHS.FEE_CALCULATOR },
  ...analyzers,
];

const CustomMenu = ({ anchorEl, open, onClose, items }) => (
  <Menu
    elevation={0}
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    open={open}
    onClose={onClose}
    TransitionProps={{ unmountOnExit: true, timeout: 0 }} // Disable animation
    slotProps={{ paper: { square: true } }}
  >
    {items.map((item) => (
      <MenuItem key={item.title} onClick={onClose}>
        <Typography component="a" href={item.path} style={{
          textDecoration: 'none',
          fontWeight: '500',
          fontSize: 16,
          color: "#333",
        }}>{item.title}</Typography>
      </MenuItem>
    ))}
  </Menu>
);

const SignUpButton = ({ label, onClick, href, isDropDown, variant, marginLeft, marginRight }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const buttonProps = {
    disableRipple: true,
    onClick: onClick,
    variant,
    sx: {
      height: '35px',
      borderRadius: 2,
      my: 2,
      color: '#261b3a',
      ml: marginLeft,
      mr: marginRight,
      pr: (isMobile ? 1 : 2),
      pl: (isMobile ? 1 : 2),
      textTransform: 'none',
      fontSize: 14,
      textDecoration: 'none',
      backgroundColor: colors.wafer,
    },
  };

  if (href) {
    return (
      <a href={href} style={{ textDecoration: 'none' }}>
        <Button {...buttonProps}>{label}</Button>
      </a>
    );
  }

  return (
    <Button {...buttonProps} endIcon={isDropDown && <ArrowDropDown />}>
      {label}
    </Button>
  );
};

const AppBarButton = ({ label, onClick, href, isDropDown, variant, marginLeft, marginRight }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const buttonProps = {
    disableRipple: true,
    onClick: onClick,
    variant,
    sx: {
      height: '35px',
      borderRadius: 2,
      my: 2,
      color: '#EEE',
      ml: marginLeft,
      mr: marginRight,
      pr: (isMobile ? 1 : 2),
      pl: (isMobile ? 1 : 2),
      textTransform: 'none',
      fontSize: 14,
      textDecoration: 'none',
      backgroundColor: variant === "contained" ? colors.slatePurple : 'transparent',
      '&:hover': {
        backgroundColor: variant === "contained" ? colors.slatePurple : 'transparent',
        color: 'white',
        ...(href && { textDecoration: 'underline' }),
      },
    },
  };

  if (href) {
    return (
      <a href={href} style={{ textDecoration: 'none' }}>
        <Button {...buttonProps}>{label}</Button>
      </a>
    );
  }

  return (
    <Button {...buttonProps} endIcon={isDropDown && <ArrowDropDown />}>
      {label}
    </Button>
  );
};

export const ResponsiveAppBar = ({ style, hideLinks }) => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { isSignedIn } = useAuth();
  const navigate = useNavigate();

  const handleOpenUserMenu = (event, menu) => {
    setAnchorElUser(event.currentTarget);
    setSelectedMenu(menu);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const Logo = ({ color }) => {
    return (<Typography
      noWrap
      component="a"
      href={PATHS.HOME}
      style={{
        fontSize: '0.9rem',
        fontFamily: "Libre Baskerville",
        opacity: 0.7
      }}
      sx={{
        mb: 0.2,
        fontWeight: 'bold',
        letterSpacing: '0.12rem',
        color: 'inherit',
        textDecoration: 'none',
      }}
    >
      POSHWATCH
    </Typography>)
  }

  const renderSignInButton = () => {
    if (isSignedIn === null) {
      return (<></>);
    }

    return !!isSignedIn ?
      <AppBarButton label="Settings" href={PATHS.SETTINGS} /> :
      <SignUpButton label="Sign up for free" href={PATHS.REGISTER} variant="contained" marginLeft={2} />
  }

  const drawerContent = (
    <List sx={{ mt: 1 }}>
      <Box sx={{ pl: 2, pr: 2, pt: 0, pb: 2 }}>
        <Logo />
      </Box>

      <Box sx={{ pb: 2 }}>
        <Divider />
      </Box>

      <Typography variant="subtitle1" sx={{ fontWeight: '500', ml: 2 }}>
        Charts
      </Typography>
      {charts.map((item) => (
        <ListItemButton dense component="a" href={item.path} key={item.title} sx={{ pl: 4 }}>
          <ListItemText primary={item.title} />
        </ListItemButton>
      ))}
      <Typography variant="subtitle1" sx={{ fontWeight: '500', ml: 2 }}>
        Tools
      </Typography>
      {tools.map((item) => (
        <ListItemButton dense component="a" href={item.path} key={item.title} sx={{ pl: 4 }}>
          <ListItemText primary={item.title} />
        </ListItemButton>
      ))}
      <Typography variant="subtitle1" sx={{ fontWeight: '500', ml: 2 }}>
        Other
      </Typography>
      <ListItemButton dense component="a" href={PATHS.GUIDES} sx={{ pl: 4 }}>
        <ListItemText primary="Guides" />
      </ListItemButton>
      <ListItemButton dense component="a" onClick={isSignedIn ? () => {
        navigate(PATHS.SETTINGS)
      } : null} href={!isSignedIn ? PATHS.PREMIUM : undefined} sx={{ pl: 4 }}>
        <ListItemText primary={isSignedIn ? "Settings" : "Join Now"} />
      </ListItemButton>
    </List>
  );

  return (
    <AppBar
      position="static"
      elevation={0}
      style={style}>
      <Container maxWidth="md" >
        <Toolbar disableGutters>
          <Logo />

          <div style={{
            flexGrow: 1,
          }} />

          {!hideLinks && (
            <div>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={toggleDrawer(true)}
                sx={{ display: { xs: 'block', sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                PaperProps={{ elevation: 0 }}
              >
                {drawerContent}
              </Drawer>
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <AppBarButton
                  label="Charts"
                  isDropDown
                  onClick={(e) => handleOpenUserMenu(e, 'Charts')} />
                <CustomMenu
                  anchorEl={anchorElUser}
                  open={Boolean(anchorElUser) && selectedMenu === 'Charts'}
                  onClose={handleCloseUserMenu}
                  items={charts}
                />
                <AppBarButton
                  label="Tools"
                  isDropDown
                  onClick={(e) => handleOpenUserMenu(e, 'Tools')} />
                <CustomMenu
                  anchorEl={anchorElUser}
                  open={Boolean(anchorElUser) && selectedMenu === 'Tools'}
                  onClose={handleCloseUserMenu}
                  items={tools}
                />
                <AppBarButton label="Guides" href={PATHS.GUIDES} />
                {renderSignInButton()}
              </Box>
            </div>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
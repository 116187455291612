import React from 'react';
import { Box, Container } from '@mui/joy';
import Dashboard from '../../pages/dashboard/Dashboard.tsx';
import { Helmet } from 'react-helmet-async';

// You'll need to import these images
import logo from '../../images/logo/logo.png';
import { Typography } from '@mui/material';

export const AppDownload = () => {
  return (
    <Dashboard>
    <Helmet>
      <title>Download Poshwatch</title>
      <meta name="description" content="Download Poshwatch for iOS or Android" />
      <meta property="og:title" content="Download Poshwatch" />
      <meta property="og:description" content="Download Poshwatch for iOS or Android" />
      <meta property="og:url" content="https://poshwatch.io/app" />
      <meta property="og:type" content="website" />
      <link rel="canonical" href={window.location.href} />
    </Helmet>

    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        py: 8,
        gap: 4,
      }}
    >
      <Box
        component="img"
        src={logo}
        alt="Poshwatch Logo"
        sx={{
          width: '160px',
          height: 'auto',
          borderRadius: '36px',
          boxShadow: 'lg',
        }}
      />

      <Typography variant="h4" fontWeight="bold" textAlign="center">
        Download for iOS or Android
      </Typography>

      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap',
          justifyContent: 'center',
          '& > a': {
            margin: '0 8px',
          },
        }}
      >
        <Box
          component="a"
          href="https://apps.apple.com/us/app/poshwatch-reseller-tools/id6502268040?platform=iphone"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            display: 'inline-block',
            height: '60px',
            '&:hover': {
              opacity: 0.8,
            },
          }}
        >
          <img
            src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
            alt="Download on the App Store"
            style={{
              height: '100%',
              width: 'auto',
            }}
          />
        </Box>

        <Box
          component="a"
          href="https://play.google.com/store/apps/details?id=io.poshwatch.mobile"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            display: 'inline-block',
            height: '60px',
            '&:hover': {
              opacity: 0.8,
            },
          }}
        >
          <img
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            alt="Get it on Google Play"
            style={{
              height: '100%',
              width: 'auto',
              transform: 'scale(1.47)',
            }}
          />
        </Box>
      </Box>
    </Container>
    </Dashboard>
  );
};

import React from "react";
import PATHS from '../../utils/paths';
import useEbayFeeCalculator from "../../hooks/useEbayFeeCalculator";
import { Box, Typography, Input, Card, Grid, Divider, Button, ButtonGroup, Select, Option } from '@mui/joy';
import { Helmet } from 'react-helmet-async';
import Dashboard from '../dashboard/Dashboard.tsx';
import { FEE_STRUCTURES, EBAY_SELLER_LEVELS, EBAY_STORE_TYPES, OVERSEAS_SALES } from '../../utils/calculator.ts';

const StyledInput = ({ startDecorator, endDecorator, value, onChange, ...props }) => (
  <Input
    variant="soft"
    size="lg"
    startDecorator={startDecorator}
    endDecorator={endDecorator}
    value={value}
    onChange={onChange}
    placeholder="0.00"
    sx={{ width: '120px', '& input': { textAlign: 'right' } }}
    {...props}
  />
);

const InputRow = ({ label, value, color, children }) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typography level="body-md">{label}</Typography>
      {color && <Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: color }} />}
    </Box>
    {children}
  </Box>
);

const EbayCalculator = () => {
  const canonicalUrl = `${window.location.origin}${PATHS.EBAY_FEE_CALCULATOR}`;
  const {
    cost,
    setCost,
    salePrice,
    setSalePrice,
    salesTax,
    setSalesTax,
    salesTaxAmount,
    totalEbayFee,
    finalValueFee,
    insertionFee,
    grossProfit,
    profitMargin,
    country,
    setCountry,
    category,
    setCategory,
    shippingCharges,
    setShippingCharges,
    shippingCost,
    setShippingCost,
    quantity,
    setQuantity,
    sellerLevel,
    setSellerLevel,
    storeType,
    setStoreType,
    overseasSales,
    setOverseasSales,
  } = useEbayFeeCalculator('US');
  const EBAY_CATEGORIES = Object.keys(FEE_STRUCTURES[country]);

  const colors = {
    netProfit: '#2196f3',    // blue
    cost: '#f44336',         // red
    shippingCost: '#3f51b5', // indigo
    salesTax: '#ffc107',     // amber
    fees: '#9c27b0',         // purple
  };

  const renderCurrencyToggle = () => (
    <ButtonGroup variant="soft" sx={{ alignSelf: 'flex-start' }}>
      {[
        { code: 'US', symbol: '🇺🇸', label: 'USD' },
        { code: 'CA', symbol: '🇨🇦', label: 'CAD' }
      ].map(({ code, symbol, label }) => (
        <Button
          key={code}
          onClick={() => setCountry(code)}
          variant={country === code ? 'soft' : 'soft'}
          color={country === code ? 'primary' : 'neutral'}
          sx={{
            display: 'flex',
            gap: 1,
            minWidth: '90px',
            bgcolor: country === code ? 'var(--joy-palette-primary-200)' : undefined,
          }}
        >
          <Typography fontSize="1.2rem">{symbol}</Typography>
          <Typography level="body-md">{label}</Typography>
        </Button>
      ))}
    </ButtonGroup>
  );

  const renderCategorySelect = () => (
    <InputRow label="Item Category">
      <Select
        value={category}
        variant="soft"
        onChange={(event, newValue) => {
          setCategory(newValue);
        }}
      >
        {EBAY_CATEGORIES.map((cat) => (
          <Option key={cat} value={cat}>
            {cat}
          </Option>
        ))}
      </Select>
    </InputRow>
  );

  const renderOverseasToggle = () => (
    <InputRow label="International Sales">
      <Select
        value={overseasSales}
        variant="soft"
        onChange={(event, newValue) => {
          setOverseasSales(newValue);
        }}
      >
        {OVERSEAS_SALES[country].map((selection) => (
          <Option key={selection} value={selection}>
            {selection}
          </Option>
        ))}
      </Select>
    </InputRow>
  );

  const renderPriceInputs = () => (
    <>
      <InputRow label="Sale Price">
        <StyledInput
          startDecorator="$"
          value={salePrice}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*\.?\d{0,2}$/.test(value) || value === '') {
              setSalePrice(value);
            }
          }}
        />
      </InputRow>
      <InputRow label="Quantity">
        <StyledInput
          value={quantity}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*$/.test(value) || value === '') {
              setQuantity(value);
            }
          }}
        />
      </InputRow>
      <InputRow label="Cost of Goods" color={colors.cost}>
        <StyledInput
          startDecorator="$"
          value={cost}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*\.?\d{0,2}$/.test(value) || value === '') {
              setCost(value);
            }
          }}
        />
      </InputRow>
      <InputRow label="Shipping Charges">
        <StyledInput
          startDecorator="$"
          value={shippingCharges}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*\.?\d{0,2}$/.test(value) || value === '') {
              setShippingCharges(value);
            }
          }}
        />
      </InputRow>
      <InputRow label="Shipping Cost" color={colors.shippingCost}>
        <StyledInput
          startDecorator="$"
          value={shippingCost}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*\.?\d{0,2}$/.test(value) || value === '') {
              setShippingCost(value);
            }
          }}
        />
      </InputRow>
    </>
  );

  const renderTaxesAndFees = () => (
    <>
      <Typography level="body-lg" sx={{ fontWeight: 'bold' }}>
        Taxes and Fees
      </Typography>

      <InputRow label="Sales Tax Rate">
        <StyledInput
          endDecorator="%"
          value={salesTax}
          onChange={(e) => {
            const value = e.target.value;
            setSalesTax(parseFloat(value) || 0);
          }}
        />
      </InputRow>

      <InputRow label="Sales Tax Amount" color={colors.salesTax}>
        <Typography level="body-md">
          ${Math.abs(salesTaxAmount).toFixed(2)}
        </Typography>
      </InputRow>
      <Box></Box>
      <InputRow label="ebay Insertion Fee">
        <Typography level="body-md">
          ${Math.abs(insertionFee).toFixed(2)}
        </Typography>
      </InputRow>
      <InputRow label="ebay Final Value Fee">
        <Typography level="body-md">
          ${Math.abs(finalValueFee).toFixed(2)}
        </Typography>
      </InputRow>

      <InputRow label="Total eBay Fee" color={colors.fees}>
        <Typography level="body-md">
          ${Math.abs(totalEbayFee).toFixed(2)}
        </Typography>
      </InputRow>
    </>
  );

  const renderStoreSettings = () => (
    <>
      <Typography level="body-lg" sx={{ fontWeight: 'bold' }}>
        Store Settings
      </Typography>
      <InputRow label="Type">
        <Select
          value={storeType}
          variant="soft"
          onChange={(e, newValue) => {
            setStoreType(newValue);
          }}
        >
          {EBAY_STORE_TYPES[country].map((type) => (
            <Option key={type} value={type}>
              {type}
            </Option>
          ))}
        </Select>
      </InputRow>
      <InputRow label="Seller Level">
        <Select
          value={sellerLevel}
          variant="soft"
          onChange={(e, newValue) => {
            setSellerLevel(newValue);
          }}
        >
          {EBAY_SELLER_LEVELS.map((level) => (
            <Option key={level} value={level}>
              {level}
            </Option>
          ))}
        </Select>
      </InputRow>
    </>
  );

  return (
    <Dashboard>
      <Helmet >
        <title>Ebay Fee Calculator - Calculate your net profits after all your fees and expenses</title>
        <meta name="description" content="Determine your eBay fees and potential profits after all your expenses using our free eBay calculator" />
        <meta property="og:title" content="eBay Fee calculator" />
        <meta property="og:description" content="Calculate Your Profits Instantly" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <Box>
        <Typography level="h2" sx={{
          fontSize: "1.8em",
          fontWeight: 'bold',
          fontFamily: "Libre Baskerville",
          pb: 1
        }}>
          eBay Calculator
        </Typography>
        <Typography level="body-md" sx={{ mb: 4, color: 'text.secondary' }}>
          Calculate your net profits after all your fees and expenses.
        </Typography>

        <Grid container justifyContent="left" marginTop={4}>
          <Grid xs={12} md={8} bgcolor="#F9FAFB" border={1} borderColor="neutral.200" borderRadius={16} padding={4}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              px: 1
            }}>
              {renderCurrencyToggle()}

              {renderPriceInputs()}

              {renderCategorySelect()}

              {renderOverseasToggle()}

              <Divider />

              {renderStoreSettings()}

              <Divider />

              {renderTaxesAndFees()}
            </Box>

            <Card
              variant="outlined"
              sx={{
                p: 3,
                mt: 3,
                bgcolor: 'white',
                boxShadow: 'lg',
                borderRadius: 'xl'
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography level="body-lg" fontWeight="lg">Profit Margin</Typography>
                </Box>
                <Typography level="body-md" fontWeight="lg" sx={{ color: profitMargin < 0 ? 'danger.500' : 'success.500' }}>
                  {profitMargin.toFixed(1)}%
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography level="body-lg" fontWeight="lg">Net Profit</Typography>
                  <Box sx={{ width: 8, height: 8, borderRadius: '50%', bgcolor: colors.netProfit }} />
                </Box>
                <Typography level="h2" sx={{
                  color: salePrice === 0 && cost === 0
                    ? 'neutral.500'
                    : grossProfit < 0
                      ? 'danger.500'
                      : 'success.500',
                }}>
                  {salePrice === 0 && cost === 0
                    ? '$0.00'
                    : grossProfit < 0
                      ? `-$${Math.abs(grossProfit).toFixed(2)}`
                      : `$${grossProfit.toFixed(2)}`}
                </Typography>
              </Box>

              <Box sx={{ mt: 2 }}>
                <Box sx={{
                  height: 8,
                  borderRadius: 4,
                  display: 'flex',
                  overflow: 'hidden'
                }}>
                  <Box sx={{
                    width: `${(cost / salePrice) * 100}%`,
                    bgcolor: colors.cost
                  }} />
                   <Box sx={{
                    width: `${(totalEbayFee / salePrice) * 100}%`,
                    bgcolor: colors.fees
                  }} />
                  <Box sx={{
                    width: `${(salesTaxAmount / salePrice) * 100}%`,
                    bgcolor: colors.salesTax
                  }} />
                  <Box sx={{
                    width: `${(shippingCost / salePrice) * 100}%`,
                    bgcolor: colors.shippingCost
                  }} />
                  <Box sx={{
                    width: `${(grossProfit / salePrice) * 100}%`,
                    bgcolor: colors.netProfit
                  }} />
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Dashboard>
  );
};

export default EbayCalculator;
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ResponsiveAppBar } from '../../components/ResponsiveAppBar';
import Footer from '../../components/Footer';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet-async';
import Panel from '../../components/Panel';
import { lightTheme } from '../../customTheme';
import { useAuth } from '../../contexts/AuthContext';
import SignInCard from '../../components/SignInCard';
import Box from '@mui/material/Box';
import PATHS from '../../utils/paths';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { MyFirebase } from '../../api/my_firebase.ts';

const SignIn = () => {
  const { isSignedIn } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isResetForm, setIsResetForm] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const fromPricing = searchParams.get('from') === 'pricing';

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await MyFirebase.getInstance().resetPassword(resetEmail);
      setResetMessage('Password reset email sent! Please check your inbox.');
    } catch (error) {
      setResetMessage('Error: Failed to send reset email. Please try again.');
    }
  };

  React.useEffect(() => {
    if (isSignedIn) {
      if (fromPricing) {
        navigate(PATHS.PRICING);
      } else {
        navigate(PATHS.PRODUCT_RESEARCH);
      }
    }
  }, [isSignedIn, navigate, fromPricing]);

  const renderResetForm = () => (
    <Card variant="outlined" style={{ minWidth: "400px" }}>
      <CardContent style={{ padding: "30px" }}>
        <Typography variant="h4" fontFamily={"Libre Baskerville"}>
          Reset Password
        </Typography>
        <Typography variant="body1" paddingTop={2} paddingBottom={4}>
          Enter your email to receive a password reset link.
        </Typography>
        <form onSubmit={handleResetPassword}>
          <TextField
            fullWidth
            type="email"
            label="Email"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
            required
            margin="normal"
          />
          {resetMessage && (
            <Typography color={resetMessage.includes('Error') ? 'error' : 'success'} paddingY={2}>
              {resetMessage}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: '16px' }}
          >
            Send Reset Link
          </Button>
          <Box paddingBottom={2} paddingTop={2}>
            <Divider />
          </Box>
          <Button
            onClick={() => setIsResetForm(false)}
            variant="text"
            color="primary"
            fullWidth
            style={{ textTransform: 'none', textDecoration: 'underline' }}
          >
            Back to Sign In
          </Button>
        </form>
      </CardContent>
    </Card>
  );

  const renderSignInForm = () => (
    <Card variant="outlined" style={{ minWidth: "400px" }}>
      <CardContent style={{ padding: "30px" }}>
        <Typography variant="h4" fontFamily={"Libre Baskerville"}>
          Sign in
        </Typography>
        <Typography variant="body1" paddingTop={2} paddingBottom={4}>
          To access additional features.
        </Typography>
        <SignInCard />
        <Box paddingBottom={2} paddingTop={2}>
          <Divider />
        </Box>
        <Button
          onClick={() => {
            setIsResetForm(true);
            setResetMessage('');
          }}
          variant="text"
          color="primary"
          fullWidth
          style={{ textTransform: 'none', textDecoration: 'underline', marginBottom: '8px' }}
        >
          Forgot Password?
        </Button>
        <Button
          onClick={() => navigate(PATHS.REGISTER)}
          variant="text"
          color="primary"
          fullWidth
          style={{ textTransform: 'none', textDecoration: 'underline' }}
        >
          Don't have an account? Sign Up
        </Button>
      </CardContent>
    </Card>
  );

  return (
    <div style={{ position: 'relative' }}>
      {!isSignedIn && <>
        <Helmet>
          <title>{isResetForm ? 'Reset Password' : 'Sign in'}</title>
          <meta name="description" content={isResetForm ? "Reset your Poshwatch password" : "Sign in to Poshwatch"} />
        </Helmet>
        <ResponsiveAppBar hideLinks />
        <Panel theme={lightTheme}>
          <Container maxWidth='sm' style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
            {isResetForm ? renderResetForm() : renderSignInForm()}
          </Container>
        </Panel>
        <Footer hideLinks />
      </>}
    </div>
  );
};

export default SignIn;
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';

export interface ImageUploadResult {
  path: string;
  url: string;
}

/**
 * Uploads an image file to Firebase Storage and returns the download URL
 * @param file - The image file to upload
 * @param path - The storage path where the image should be saved (e.g., 'images/profile-pics')
 * @returns Promise with the download URL of the uploaded image
 */
export const uploadImage = async (file: File, path: string): Promise<ImageUploadResult> => {
  try {
    const storage = getStorage();
    const fileExtension = file.name.split('.').pop();
    const fileName = `${crypto.randomUUID()}.${fileExtension}`;
    const storageRef = ref(storage, `${path}/${fileName}`);

    // Upload the file to Firebase Storage
    const snapshot = await uploadBytes(storageRef, file);
    
    // Get the download URL
    const downloadURL = await getDownloadURL(snapshot.ref);
    return {
      path: `${path}/${fileName}`,
      url: downloadURL
    };
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

/**
 * Deletes an image from Firebase Storage using its full storage path
 * @param imagePath - The complete storage path of the image to delete (e.g., 'images/profile-pics/image.jpg')
 * @returns Promise that resolves when the image is deleted
 */
export const deleteImage = async (imagePath: string): Promise<void> => {
  try {
    const storage = getStorage();
    const imageRef = ref(storage, imagePath);
    await deleteObject(imageRef);
  } catch (error) {
    console.error('Error deleting image:', error);
    throw error;
  }
};
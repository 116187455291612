import React, { useState } from 'react';
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Divider,
  CircularProgress,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { darkTheme } from '../customTheme';
import SearchTwoTone from '@mui/icons-material/SearchTwoTone';
import PATHS from '../utils/paths';
import SearchSuggestions from './SearchSuggestions.tsx';
import { useNavigate } from 'react-router-dom';
import { PhotoCameraTwoTone } from '@mui/icons-material';
import { useSearch } from '../pages/search/hooks/useSearch.ts';

const ToolsCard: React.FC = () => {
  const [query, setQuery] = useState('');
  const navigate = useNavigate();
  const enableCamera = true;

  const { imageToQuery, loading } = useSearch();

  const handleProductSearch = () => {
    if (query.trim() !== '') {
      navigate(`${PATHS.PRODUCT_RESEARCH}?q=${query}`);
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ pointerEvents: loading ? 'none' : 'auto' }}>
        <TextField
          fullWidth
          variant="filled"
          placeholder="Enter a style"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleProductSearch();
            }
          }}
          sx={{
            mt: 0,
            '& .MuiFilledInput-root': {
              backgroundColor: 'white', // Set background to white
              borderRadius: '300px', // Rounded corners
              height: '60px', // Increase height
              fontSize: '18px', // Increase font size
              display: 'flex', // Flex container for alignment
              alignItems: 'center', // Center items vertically
              '&:hover': {
                backgroundColor: 'white', // Preserve white on hover
              },
              '&.Mui-focused': {
                backgroundColor: 'white', // Preserve white on focus
              },
            },
            '& .MuiFilledInput-input': {
              padding: '15px 12px', // Adjust padding for better alignment
              fontSize: '18px', // Match font size
            },
            '& .MuiInputAdornment-root': {
              height: '100%', // Match the height of the TextField
              display: 'flex', // Flex container for icon alignment
              alignItems: 'center', // Center icons vertically
            },
            '& .MuiInputBase-input': {
              color: 'black', // Black text color
            },
            '& .MuiFilledInput-underline': {
              '&:before, &:after': {
                display: 'none !important',
                border: 'none !important',
                borderBottom: 'none !important',
              },
              '&:hover:before': {
                display: 'none !important',
                border: 'none !important',
                borderBottom: 'none !important',
              }
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {enableCamera && !loading && (
                  <>
                    <IconButton
                      onClick={() => {
                        const input = document.createElement('input');
                        input.type = 'file';
                        input.accept = 'image/*';
                        input.capture = 'environment';
                        input.click();
                        input.onchange = async (e) => {
                          const file = (e.target as HTMLInputElement).files?.[0];
                          if (file) {
                            const result = await imageToQuery(file);
                            if (result) {
                              navigate(`${PATHS.PRODUCT_RESEARCH}?q=${result}`);
                            }
                          }
                        };
                      }}
                    >
                      <PhotoCameraTwoTone sx={{ fontSize: 34 }} />
                    </IconButton>
                    <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                  </>
                )}
                <IconButton
                  onClick={handleProductSearch}
                  disabled={query.trim() === '' || loading}
                >
                  {loading ? (
                    <CircularProgress size={34} thickness={5} style={{ color: '#757575' }} />
                  ) : (
                    <SearchTwoTone sx={{ fontSize: 34 }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <SearchSuggestions
          count={3}
          variant='filled'
          borderRadius='40px'
          onSuggestionClick={(suggestion) => {
            navigate(`${PATHS.PRODUCT_RESEARCH}?q=${suggestion}`)
          }}
        />
      </Box>
    </ThemeProvider >
  );
};

export default ToolsCard;